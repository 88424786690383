.vcard-preview {
    width: 220px; /* Define uma largura fixa para os cartões */
    padding: 15px; /* Adiciona padding ao redor do conteúdo */
    margin: 10px 0; /* Espaçamento entre os cartões */
    border: 1px solid #ddd; /* Borda leve ao redor do cartão */
    border-radius: 8px; /* Bordas arredondadas */
    background-color: #ffffff; /* Cor de fundo branca */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra leve para dar destaque */
    text-align: center; /* Centraliza o conteúdo */
    font-family: Arial, sans-serif; /* Fonte padrão */
  }
  
  .vcard-preview h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .vcard-preview p {
    font-size: 0.9rem;
    margin: 5px 0;
  }
  
  .vcard-preview button {
    margin-top: 10px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .vcard-preview button:hover {
    background-color: #0056b3; /* Cor ao passar o mouse no botão */
  }
  